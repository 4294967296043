import * as Vue from 'vue'
import Layout from '@/layout'

export default {
  path: '/warehouse',
  name: 'warehouse',
  alwaysShow: true,
  component: Layout,
  meta: {title: 'menus.warehouse_management', icon: 'houses'},
  // redirect: '/warehouse/administrative_warehouse',
  children: [
    {
      path: 'administrative_warehouse',
      name: 'warehouse/administrative_warehouse/index',
      meta: {title: 'menus.administrative_warehouse', icon: 'house', affix: true},
      children: [
        {
          path: 'administrative_warehouse',
          name: 'warehouse/administrative_warehouse/index',
          component: Vue.defineAsyncComponent(
            () => import('@/views/warehouse/administrative_warehouse/index.vue')
          ),
          meta: {
            title: 'menus.administrative_warehouse',
            icon: 'house',
            affix: true,
            activeMenu: '/warehouse/administrative_warehouse'
          },
        },
        {
          path: 'material_details/:id(\\d+)',
          name: 'warehouse/administrative_warehouse/material_details',
          component: Vue.defineAsyncComponent(
            () => import('@/views/warehouse/administrative_warehouse/material_details.vue')
          ),
          hidden: true,
          meta: {
            title: 'warehouse.administrative_warehouse_material_details', icon: 'quality', affix: true,
            activeMenu: '/warehouse/administrative_warehouse'
          },
        },
        {
          path: 'application_pending_review',
          name: 'warehouse/administrative_warehouse/application_pending_review',
          component: Vue.defineAsyncComponent(
            () => import('@/views/warehouse/administrative_warehouse/application_pending_review.vue')
          ),
          hidden: true,
          meta: {
            title: 'warehouse.wait_for_review',
            activeMenu: '/warehouse/administrative_warehouse'
          },
        },
        {
          path: 'wait_for_return',
          name: 'warehouse/administrative_warehouse/wait_for_return',
          component: Vue.defineAsyncComponent(
            () => import('@/views/warehouse/administrative_warehouse/wait_for_return.vue')
          ),
          hidden: true,
          meta: {
            title: 'warehouse.my_application',
            activeMenu: '/warehouse/administrative_warehouse'
          },
        },
        {
          path: 'operation_log',
          name: 'warehouse/administrative_warehouse/operation_log',
          component: Vue.defineAsyncComponent(
            () => import('@/views/warehouse/administrative_warehouse/operation_log.vue')
          ),
          hidden: true,
          meta: {
            title: 'warehouse.operation_log',
            activeMenu: '/warehouse/administrative_warehouse'
          },
        },
      ]
    }]
}
